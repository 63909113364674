<template>
  <div>
    <title>SIM INVENTORY ~ ADD REQUEST MATERIAL</title>
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <h1>
        Add Request Material
        <br />
        <h4>Please enter data request material</h4>
        <!-- <small>Control panel</small> -->
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Dashboard</li>
      </ol>
    </section>

    <!-- Main content -->
    <section class="content">
      <!-- Small boxes (Stat box) -->
      <div class="row">
        <div class="col-md-12">
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-save"></i>
              <h3 class="box-title">Save Request Material</h3>
            </div>
            <div class="box-body">
              <div class="row">
                <div class="col-md-3">Request Number</div>
                <div class="col-md-1">:</div>
                <div class="col-md-5">
                  <input
                    type="text"
                    autocomplete="off"
                    placeholder="Request Number"
                    class="form-control"
                    v-model="reqnomor"
                  />
                </div>
              </div>

              <br />
              <div class="row">
                <div class="col-md-3">Request Date</div>
                <div class="col-md-1">:</div>
                <div class="col-md-5">
                  <input
                    type="date"
                    autocomplete="off"
                    class="form-control"
                    v-model="reqdate"
                  />
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-md-3">Product List</div>
                <div class="col-md-1">:</div>
                <div class="col-md-5">
                  <select2
                    :data="datalist"
                    :value="nomorproduk"
                    @update="viewDetail($event)"
                  ></select2>

                  <!--<select
                    @change="viewDetail($event)"
                    v-model="nomorproduk"
                    class="form-control"
                  >
                    <option disabled>--Please Select--</option>
                    <option
                      v-for="datadatalist in datalist"
                      :key="datadatalist.id"
                      :value="datadatalist.nomor_produk"
                    >
                      {{ datadatalist.nomor_produk }} - ({{
                        datadatalist.nama_produk
                      }})
                    </option>
                  </select> -->
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-md-3">Order Number</div>
                <div class="col-md-1">:</div>
                <div class="col-md-5">

                  <select2
                    :data="datalist2"
                    :value="ordenumb"
                    @update="updateordernumb($event)"
                  ></select2>


                  <!--<select v-model="ordenumb" class="form-control">
                    <option
                      v-for="datadatalist2 in datalist2"
                      :key="datadatalist2.id"
                      :value="datadatalist2.order_number"
                    >
                      {{ datadatalist2.order_number }}
                    </option>
                  </select> -->
                  <!-- <input
                    type="text"
                    autocomplete="off"
                    placeholder="Order Number"
                    class="form-control"
                    v-model="ordenumb"
                  /> -->
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-md-3">Part List</div>
                <div class="col-md-1">:</div>
                <div class="col-md-5">

                  <select2
                    :data="datapartlist"
                    :value="partlists"
                    @update="viewPart($event)"
                  ></select2>


                  <!--<select
                    @change="viewPart($event)"
                    v-model="partlists"
                    class="form-control"
                  >
                    <option
                      v-for="datadatapartlist in datapartlist"
                      :key="datadatapartlist.id"
                      :value="datadatapartlist.part_no"
                    >
                      {{ datadatapartlist.part_no }}
                    </option>
                  </select> -->
                </div>
              </div>
            </div>
            <!-- /.box-body -->
          </div>
        </div>
      </div>
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <div class="row">
        <div class="col-xs-12">
          <div class="box box-primary">
            <div class="box-header">
              <i class="fa fa-list"></i>
              <h3 class="box-title">List BOM Product Data</h3>
            </div>

            <!-- /.box-header -->
            <div v-if="isExist" class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>#</th>
                      <!-- <th>Idx</th> -->
                      <th>Product Number</th>
                      <th>Item Code</th>
                      <th>Item Name</th>
                      <th>Product Name</th>
                      <th>Part No.</th>
                      <th>Part Name</th>
                      <th>Process Code</th>
                      <th>Qty</th>
                      <th>Notes</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="classdata in list_paged3" :key="classdata.id">
                      <td>
                        <button
                          @click="saveData(classdata)"
                          type="button"
                          class="btn btn-primary"
                        >
                          <i class="fa fa-save"></i>
                        </button>
                      </td>
                      <!-- <td>{{ classdata.id }}</td> -->
                      <td>{{ classdata.nomor_produk }}</td>
                      <td>{{ classdata.kode_barang }}</td>
                      <td>{{ classdata.nama_barang }}</td>
                      <td>{{ classdata.nama_produk }}</td>
                      <td>{{ classdata.part_no }}</td>
                      <td>{{ classdata.part_name }}</td>
                      <td>{{ classdata.kode_proses }}</td>
                      <td width="100px">
                        <input
                          type="number"
                          :id="'inpb-' + classdata.id"
                          class="form-control"
                          v-model="classdata.qtyreq"
                          :name="'nmb-' + classdata.id"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          :id="'inpc-' + classdata.id"
                          class="form-control"
                          v-model="classdata.catatan"
                          :name="'nmc-' + classdata.id"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <v-pagination
                    v-model="page"
                    :pages="count"
                    :range-size="1"
                    active-color="#DCEDFF"
                    @update:modelValue="handlePageChange"
                  />
              </div>
            </div>
            <div v-else class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>#</th>
                      <!-- <th>Idx</th> -->
                      <th>Product Number</th>
                      <th>Item Code</th>
                      <th>Item Name</th>
                      <th>Product Name</th>
                      <th>Part No.</th>
                      <th>Part Name</th>
                      <th>Process Code</th>
                      <th>Qty</th>
                      <th>Notes</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="10" class="text-center">
                          <b style="color:red"> {{pesan}} </b>
                          <b style="color:red">  </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- /.box-body -->
          </div>
        </div>
      </div>
      <!-- /.row -->
      <!-- Main row -->
    </section>
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import swal from "sweetalert";
import axios from "axios";

export default {
  name: "addsum",
  components: {
    VPagination,
  },
  data() {
    return {
      isShow: false,
      isExist: true,
      nmsrc: true,
      loading: false,
      namesearch: "",
      list_paged3: [],
      page: 1,
      pagestat: 1,
      count: 1,
      datalist: [],
      datalist2: [],
      datapartlist: [],
      pageSize: 10,
      pageSizes: [10, 50, 100, 500],
      reqnomor: "",
      reqdate: "",
      qtyreq: "",
      partlists: "",
      ordenumb: "",
      nomorproduk: "",
      pesan: "",
      classdata: {
        catatan: "",
        kode_barang: "",
        qtyreq: "",
        // balance: "",
      },
    };
  },
  created() {
    this.produklist();
    this.getnow();
    this.awal();
    // this.viewDetail(event)
  },
  methods: {
    handlePageChange(value) {
      this.page = value;
      this.pagestat = 1;
      // window.scrollTo({ top: 0, behavior: "smooth" });
      this.fetchDataSumberMaterial();
    },

    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      this.fetchDataSumberMaterial();
    },
    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page;
      }

      if (pageSize) {
        params["rowcount"] = pageSize;
      }

      return params;
    },
    awal() {
      this.isExist = false
      this.pesan = "......";
    },
    updateordernumb(value){
      this.ordenumb = value
    },
    getnow() {
      const today = new Date();
      const tahun = today.getFullYear();
      var month = today.getMonth();
      if (String(month).length == 1) {
        month = "0" + (month + 1);
      } else {
        month;
      }
      var tgl = today.getDate();
      if (String(tgl).length == 1) {
        tgl = "0" + tgl;
      } else {
        tgl;
      }
      // const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      this.reqdate = tahun + "-" + month + "-" + tgl;
    },
    produklist() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin,
      };
      // const urlApiGETdetail = "http://26.183.23.191/inventory/backend/inventory/api/buyer_order/getalldatabuyer_order?length="+this.pageSize+"&page="+this.page
      const urlApiGETdetail = this.$apiurl + "produk_h/getdaftar_produk_list";
      axios
        .get(urlApiGETdetail, { headers: headers })
        .then((resp) => {
          // console.log(resp);
          this.datalist = resp.data.data;
          this.datalist.forEach((item) => {
            item.value = item.nomor_produk;
            item.label = item.nama_produk;
          });
          // this.datapartlist = resp.data.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
          })
        });
    },
    viewDetail(value) {
      this.nomorproduk = value
      this.ordenumb = ''
      this.partlists = ''
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      // const datalistt = event.target.value;
      const urlAPIget =
        this.$apiurl +
        // "produk_d/getalldataproduk_d?page=1&length=5000&nomor_produk=" +
        "buyer_order/getdaftar_buyer_order_list_by_nomor_produk?nomor_produk=" +
        // datalistt;
        this.nomorproduk;
      const urlAPIget2 =
        this.$apiurl +
        "produk_d/getalldataproduk_d?page=1&length=5000&nomor_produk=" +
        // "buyer_order/getbuyer_orderbynomor_produk?nomor_produk=" +
        // datalistt;
        this.nomorproduk;

      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin,
      };
      // console.log(datalistt);
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          //console.log(resp.data.pesan);
          if(resp.data.pesan == 'Data not found') {
            this.isExist = false;
            this.pesan = '... Data Not Found ...'
            this.ordenumb = ''
            this.datalist2 = ''
            this.loading = false;
          } else {
            this.isExist = true
            this.list_paged3 = resp.data.data;
            // this.datapartlist = resp.data.data;
            this.datalist2 = resp.data.data;
            this.datalist2.forEach((item) => {
              item.value = item.order_number;
              item.label = item.order_number;
            });
            this.loading = false;
          }
        })
        .catch((err) => {
          // console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err,
          })
        });
      axios
        .get(urlAPIget2, { headers: headers })
        .then((resp) => {
          console.log(resp)
          this.list_paged3 = resp.data.data;
          this.datapartlist = resp.data.data;
          this.datapartlist.forEach((item) => {
              item.value = item.part_no;
              item.label = item.part_no;
            });
          // this.datalist2 = resp.data.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
          })
        });
    },
    viewPart(value) {
      this.loading = true;
      this.partlists = value
      const tokenlogin = sessionStorage.getItem("token");
      // const datalistt = event.target.value;
      const urlAPIget =
        this.$apiurl +
        "produk_h/getall_list_bom_dataproduk_h?page=1&length=5000&part_no=" +
        // datalistt;
        this.partlists;

      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin,
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          // console.log(resp);
          if(resp.data.data.length == 0) {
            this.isExist = false;
            this.pesan = '... Data Not Found ...'
            this.loading = false;
          } else {
            this.isExist = true;
            this.list_paged3 = resp.data.data;
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
          })
        });
    },
    saveData: function (classdata) {
      this.loading = true;
      var nop = this.reqnomor;
      var datereq = this.reqdate;
      var catat = classdata.catatan;
      var qtyreq = classdata.qtyreq;

      if (nop == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Product Number can not be empty",
          showConfirmButton: false,
        });
        this.loading = false;
      } else if (datereq == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Request Date can not be empty",
          showConfirmButton: false,
        });
        this.loading = false;
      } else if (catat == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes can not be empty",
          showConfirmButton: false,
        });
        this.loading = false;
      } else if (qtyreq == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Qty Request not be empty",
          showConfirmButton: false,
        });
        this.loading = false;
      } else {
        // const tokenlogin = localStorage.getItem("token");
        const kodeuser = sessionStorage.getItem("kodeuser");
        const paramdata = {
          nomor_request: this.reqnomor,
          nomor_order: this.ordenumb,
          notes: classdata.catatan,
          qty: classdata.qtyreq,
          kode_user: kodeuser,
          kode_barang: classdata.kode_barang,
          tanggal_request: this.reqdate,
        };
        const tokenlogin = sessionStorage.getItem("token");
        const urlAPIsavedata =
          this.$apiurl + "request_material/saverequest_material";

        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin,
        };
        axios
          .post(urlAPIsavedata, paramdata, { headers })
          .then((resp) => {
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Save data successfully",
                showConfirmButton: false,
              });
              sessionStorage.removeItem("noprd");
              this.loading = false;
              // this.$router.push({ name: "requestmaterial" });
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Save data failed",
                showConfirmButton: false,
              });
              this.loading = false;
              // this.$router.push({ name: "requestmaterial" });
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false,
            });
            console.log(err);
            this.loading = false;
          });
      }
    },
    async fetchDataSumberMaterial() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin,
      };
      // const urlApiGETdetail = "http://26.183.23.191/inventory/backend/inventory/api/buyer_order/getalldatabuyer_order?length="+this.pageSize+"&page="+this.page
      const urlApiGETdetail =
        this.$apiurl +
        "request_material/getalldatav_sumber_material_request?length=" +
        this.pageSize +
        "&page=" +
        this.page +
        "&nomor_produk=" +
        this.$route.params.noprod;
      axios
        .get(urlApiGETdetail, { headers: headers })
        .then((resp) => {
          // console.log(resp);
          this.list_paged3 = resp.data.data;
          this.count = resp.data.data.datatotalcount;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
          }).then(function (isConfirm) {
            if (isConfirm) {
              window.location.href = "/";
            }
          });
        });
    },
    searchdata() {
      this.loading = true;
      this.nmsrc = true;
      // const params = this.getRequestParams(this.page);
      const params = this.getRequestParams(this.pagestat);
      if (this.namesearch == "") {
        window.location.reload();
        this.loading = true;
        this.fetchData();
      } else {
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/supplier/getalldatasupplier?cari=" + this.namesearch+"&length="+this.pageSize;
        const urlAPIget =
          this.$apiurl +
          "request_material/getalldatav_sumber_material_request?cari=" +
          this.namesearch +
          "&length=" +
          this.pageSize +
          "&nomor_produk=" +
          this.$route.params.noprod;
        axios
          .get(urlAPIget, { params, headers: { Authorization: tokenlogin } })
          .then((resp) => {
            this.list_paged3 = resp.data.data;
            this.count = resp.data.datatotalcount;
            this.loading = false;
            this.nmsrc = false;
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
            }).then(function (isConfirm) {
              if (isConfirm) {
                window.location.href = "/";
              }
            });
          });
      }
    },
  },
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>